import styled from "styled-components";

function AppLayout({ children }: any) {
  return <StyledLayout className="app-layout">{children}</StyledLayout>;
}

const StyledLayout = styled.main`
  &.app-layout,
  /* for react-router-loading */
  &.app-layout > div , {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;
    background-color: #dddddd;
  }
`;

export default AppLayout;
