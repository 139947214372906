import axios from "axios";
import dayjs from "dayjs";
import HeartAttention from "icon/HeartAttention";
import HeartAttentioned from "icon/HeartAttentioned";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import Heart from "../icon/HeartIcon";

import Logo from "/public/img/Logo.png";

function postEmailQuery(payload: any) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://sheet.best/api/sheets/3f165f94-1458-4195-8e04-592f7ca43529",
        payload
      )
      .then((res) => resolve(res.data))
      .catch(reject);
  });
}

function Content() {
  const [isAttentioned, setIsAttentioned] = useState(false);
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      email: ""
    }
  });
  const { isLoading, mutateAsync } = useMutation(postEmailQuery, {
    onSuccess() {
      toast.success("บันทึกสำเร็จ");
    },
    onError() {
      toast.error("บันทึกไม่สำเร็จ");
    }
  });

  const handleAttentionToggle = () => {
    setIsAttentioned(!isAttentioned);
  };

  async function onSubmit({ email }: any) {
    if (isLoading) {
      return;
    }
    if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      await mutateAsync({
        Email: email,
        Attentioned: isAttentioned ? "❤️" : "♡",
        TimeStamp: dayjs().format()
      });
      reset(() => ({
        email: ""
      }));
      setIsAttentioned(false);
      return;
    }
    toast.error("email ไม่ถูกต้อง, กรุณากรอกใหม่");
  }

  return (
    <Container>
      <img src={Logo} className="logo" alt="Logo Image" />
      <div className="description-box">
        <p className="slogan">
          ถ้าคุณกำลังมองหาหอที่ &quot;โอ้โห&quot; นึกถึงเราสิ OhHor!
        </p>
        <p className="text">
          OhHor เป็นแพลตฟอร์มที่จะมาช่วยคุณหาห้องพักทุกรูปแบบ
          <br />
          ที่ตรงตามใจคุณต้องการ
        </p>
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <p>กรุณาฝาก Email ไว้, แล้วเราจะส่งให้คุณได้ทดลองใช้ก่อนใคร</p>
        <input
          className="email"
          type="email"
          placeholder="Email"
          {...register("email")}
        />
        <p>
          หากคุณสนใจมากๆ กด &apos;หัวใจ&apos; <Heart />{" "}
          เพื่อเป็นกำลังใจให้กับเรา
        </p>
        <button className="heart" type="button" onClick={handleAttentionToggle}>
          <div className="icon-ctn">
            {isAttentioned ? <HeartAttentioned /> : <HeartAttention />}
          </div>
        </button>
        <button type="submit" className="submit">
          ยืนยัน
        </button>
      </form>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 8em;
  left: 10em;
  width: 36rem;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 820px) {
    /* For mobile phones: */
    align-items: center;
    position: static;
    margin-top: 6em;
    width: 60em;
    font-size: 1em;
  }

  @media only screen and (max-width: 420px) {
    /* For mobile phones: */
    align-items: center;
    position: static;
    margin-top: 6em;
    width: 30em;
    font-size: 0.8em;
  }

  .logo {
    width: 100%;
    @media only screen and (max-width: 820px) {
      width: 90%;
    }
    object-fit: scale-down;
  }

  .description-box {
    padding: 0 1em;
    @media only screen and (max-width: 420px) {
      font-size: 0.7em;
    }

    .slogan {
      font-size: 1.6em;
      font-style: italic;
      font-weight: 400;
      margin-bottom: 0.2em;
    }

    .text {
      font-size: 1.4em;
      margin: 0;
      margin-bottom: 1.2em;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 0 1.4em 1.4em 1.4em;
    border-radius: 1.2em;
    box-shadow: 0.06em 0.37em 0.5em rgba(0, 0, 0, 0.25);
    text-align: center;

    @media only screen and (max-width: 820px) {
      /* For mobile phones: */
      width: 90%;
      font-size: 0.9em;
    }

    @media only screen and (max-width: 420px) {
      margin-top: 2em;
    }

    p {
      font-size: 0.9em;
      font-weight: 300;
    }

    .email {
      padding: 0.5em 0.8em;
      border: 1px solid #c3c6c9;
      border-radius: 1.3em;
    }

    .heart {
      width: max-content;
      background: none;
      margin-bottom: 0.6em;

      .icon-ctn {
        width: 75px;
        @media only screen and (max-width: 420px) {
          width: 45px;
        }

        filter: drop-shadow(0px 0px 10px rgba(93, 95, 99, 0.25));

        &:hover {
          filter: drop-shadow(1px 1px 11px rgba(209, 83, 83, 0.25));
        }
      }
    }

    .submit {
      height: 2em;
      color: white;
      background: #c62828;
      font-weight: 500;
      border-radius: 1.3em;

      &:hover {
        background: #ee2828;
      }
    }
  }
`;

export default Content;
