import React from "react";

function HeartIcon() {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.875 10.5719L5.05938 9.82938C2.1625 7.2025 0.25 5.46438 0.25 3.34375C0.25 1.60563 1.61125 0.25 3.34375 0.25C4.3225 0.25 5.26188 0.705625 5.875 1.42C6.48812 0.705625 7.4275 0.25 8.40625 0.25C10.1388 0.25 11.5 1.60563 11.5 3.34375C11.5 5.46438 9.5875 7.2025 6.69062 9.82938L5.875 10.5719Z"
        fill="#C62828"
      />
    </svg>
  );
}

export default HeartIcon;
