import styled from "styled-components";

import Content from "./Content";

import BgImg from "/public/img/Background.png";

function Landing() {
  return (
    <Container>
      <Content />
      <img src={BgImg} className="bg" alt="Landing Page Image" />
    </Container>
  );
}

const Container = styled.div`
  @media only screen and (max-width: 820px) {
    display: flex;
    justify-content: center;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    @media only screen and (max-width: 820px) {
      object-fit: cover;
    }
    z-index: -1;
  }
`;

export default Landing;
