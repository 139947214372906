import React from "react";

function HeartAttention() {
  return (
    <svg
      id="Layer_3"
      data-name="Layer 3"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 687.28 687.28"
    >
      <defs>
        <style>
          {`.cls-1 {
        fill: url(#linear-gradient-2);
      }`}

          {`.cls-2 {
        fill: url(#linear-gradient);
      }`}
        </style>
        <linearGradient
          id="linear-gradient"
          x1="343.64"
          y1="687.28"
          x2="343.64"
          y2="0"
          gradientTransform="translate(0 687.28) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".41" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="343.64"
          y1="472.05"
          x2="343.64"
          y2="181.15"
          gradientTransform="translate(0 687.28) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#e4e2e3" />
          <stop offset="1" stopColor="#c0bebf" />
        </linearGradient>
      </defs>
      <circle className="cls-2" cx="343.64" cy="343.64" r="343.64" />
      <path
        className="cls-1"
        d="M489.56,376.05c-32.64,47.52-63.84,77.76-145.92,130.08-82.08-52.32-113.28-82.56-145.92-130.08-32.64-47.52-20.64-88.8-20.64-88.8,0,0,16.32-73.44,84.96-72s81.6,54.24,81.6,54.24c0,0,12.96-52.8,81.6-54.24,68.64-1.44,84.96,72,84.96,72,0,0,12,41.28-20.64,88.8h0Z"
      />
    </svg>
  );
}

export default HeartAttention;
