import AppLayout from "layout/AppLayout";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";

import Landing from "./components/Landing";

import "./styles/fonts.scss";
import "./styles/reset.scss";

function App() {
  function handleError(err: Error) {
    console.error(err);
  }
  return (
    <AppLayout>
      <Toaster />
      <ErrorBoundary
        fallback={<div>Something went wrong. please refresh the page.</div>}
        onError={handleError}
      >
        <Landing />
      </ErrorBoundary>
    </AppLayout>
  );
}

export default App;
